// Generated by Framer (9bd94da)

import { addFonts, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["BpCXVNZiT"];

const serializationHash = "framer-Nvlmy"

const variantClassNames = {BpCXVNZiT: "framer-v-aw3p43"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "BpCXVNZiT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Nvlmy", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-aw3p43", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"BpCXVNZiT"} ref={ref ?? ref1} style={{background: "linear-gradient(270deg, rgba(11, 12, 14, 0.6) 0%, rgba(11, 12, 14, 0) 100%)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, ...style}}><motion.div className={"framer-42tl2r"} layoutDependency={layoutDependency} layoutId={"ZqOD74Iov"} style={{backgroundColor: "rgb(142, 132, 255)", borderBottomLeftRadius: 1, borderBottomRightRadius: 1, borderTopLeftRadius: 1, borderTopRightRadius: 1, boxShadow: "0px 0px 6px 1px rgba(153, 146, 222, 0.6)"}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Nvlmy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Nvlmy .framer-1cedkis { display: block; }", ".framer-Nvlmy .framer-aw3p43 { height: 1px; overflow: visible; position: relative; width: 100px; }", ".framer-Nvlmy .framer-42tl2r { bottom: 0px; flex: none; overflow: visible; position: absolute; right: 0px; top: 0px; width: 2px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const Framerj_RkHSnFf: React.ComponentType<Props> = withCSS(Component, css, "framer-Nvlmy") as typeof Component;
export default Framerj_RkHSnFf;

Framerj_RkHSnFf.displayName = "Shimmer";

Framerj_RkHSnFf.defaultProps = {height: 1, width: 100};

addFonts(Framerj_RkHSnFf, [])